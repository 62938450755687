<template>
  <div class="about-page">
    <div class="philosophy-container section section-gradient section-gradient-about">
      <About />
      <div v-if="false" class="section-inside">
        <img src="/images/info.png" alt="Философия" class="philosophy-image">
        <div class="section-title">Философия</div>

        <div class="about-page-description">
          В современном мире мы все больше уделяем внимания своему здоровью: оно влияет на продолжительность нашей
          жизни, внешний вид и внутреннее состояние. Всем хочется чувствовать себя легко и непринужденно.
        </div>
        <hr>
        <div class="about-sub-text">
          Понимая потребности современного общества, мы создали сервис Meal Deal – сервис, посвященный здоровому
          образу жизни. Мы хотим помочь людям обрести гармонию со своим телом путем познания принципов правильного
          питания, спортивного заряда и комфортного состояния всего организма.
        </div>
        <h3>Meal Deal разделен на три части, и каждый сможет найти то, что ему по душе:</h3>
        <div class="about-section">
          <div class="about-number about-number-1">1</div>
          <div class="about-page-description-container">
            <div class="about-page-description">
              Статьи о здоровом образе жизни, нашем организме и теле. Здесь найдут для себя полезные знания как
              новички,
              так и опытные пропагандисты принципов ЗОЖ
            </div>
          </div>
        </div>
        <div class="about-section">
          <div class="about-number about-number-2">2</div>
          <div class="about-page-description-container">
            <div class="about-page-description">
              План питания, составленный специально под ваши цели. Опытные и сертифицированные диетологи составили для
              наших пользователей меню на каждый день, сбалансированное и одновременно с тем разнообразное. А чтобы
              вам
              было удобнее, мы расписали рецепты завтраков, обедов, ужинов и даже перекусов, тщательно отбирая для вас
              самое лучшее.
            </div>
            <router-link to="/plan" class="btn-outlined btn-gray btn-full-width">планы</router-link>
          </div>
        </div>
        <div class="about-section">
          <div class="about-number about-number-3">3</div>
          <div class="about-page-description-container">
            <div class="about-page-description">
              Консультации с диетологами, позволяющие вам получить абсолютно индивидуальный подход. Мы поможем вам
              получить ответ на любой вопрос: начиная от самого простого вроде «с чем лучше сочетать картофельное
              пюре» и
              заканчивая составлением индивидуального плана питания и тренировок, подобранного согласно вашим
              параметрам:
              росту и весу, возрасту, состоянию вашего здоровья, вашего распорядка дня и ваших вкусовых предпочтений.
            </div>
            <router-link to="/consultations" class="btn-outlined btn-gray btn-full-width">консультации</router-link>
          </div>
        </div>
      </div>
      <div class="tele2-container section">
        <div class="section-inside" v-html="content" />
      </div>
      <div class="section section-gradient section-gradient-about-footer support-section">
        <div class="section-inside">
          <div class="support-container">
            <h3 class="support-h3">Если возникли вопросы напишите нам на почту или обратитесь в службу поддержки</h3>
            <!--          <div class="support-label">E-mail</div>-->
            <div class="_support-row">
              <div class="support-email">
                <a href="mailto:service@meal-deal.ru">service@meal-deal.ru</a>
              </div>
              <div class="support-btn-container">
                <router-link to="/feedback" class="btn">Написать в поддержку</router-link>
              </div>
            </div>
          </div>
          <p v-if="false" class="support-bottom">
            ИП Куликова С. Д.<br>
            ОГРНИП 320774600015546
          </p>
        </div>
      </div>
      <app-footer />
    </div>
  </div>
</template>

<script>
import AppFooter from '@/components/app/Footer';
import About from '@/components/app/About';

export default {
  components: {
    AppFooter,
    About
  },
  computed: {
    content() {
      return window._content?.PREVIEW_TEXT_T2;
    }
  }
}
</script>

<style scoped lang="scss">
._support-row {
  margin-top: 60px;

  @media screen and (max-width: 620px) {
    margin-top: 0;
  }

  .support-email {
    margin-bottom: 50px;
  }

  @media screen and (min-width: 621px) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .support-email {
      margin-bottom: 0;
    }
  }
}

.support-container {
  margin-top: 99px;

  @media screen and (max-width: 620px) {
    margin-top: 50px;
  }
}
</style>
